import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ArticleComponent from './TutorialsDetailsComponent/ArticleComponent/ArticleComponent';
import TutorialHeadlineFormat from './TutorialsDetailsComponent/TutorialHeadlineFormat/TutorialHeadlineFormat';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Tutorialdetails.css';

const TutorialDetails = () => {
    const { name } = useParams(); // Get the tutorial name from the URL
    const [interviews, setInterviews] = useState([]); // State to store fetched interviews
    const [selectedTopic, setSelectedTopic] = useState(null); // State to track the clicked topic
    const [filteredContent, setFilteredContent] = useState(null); // State to store filtered content
    const location = useLocation();
    const { state } = location;
    const img = state ? state.img : null; // Get image from state, if available

    useEffect(() => {
        if (name) {
            const fetchInterviews = async () => {
                try {
                    const url = 'https://api-1-122j.onrender.com/language/read'; // Replace with actual API URL
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }
                    const data = await response.json();
                    const filteredInterviews = data.filter(javascripts => javascripts.title.toLowerCase() === name.toLowerCase());
                    setInterviews(filteredInterviews); // Update the state with filtered data
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchInterviews();
        }
    }, [name]);

    // Fetch content when a topic is selected
    useEffect(() => {
        if (selectedTopic) {
            const fetchContent = async () => {
                try {
                    const url = 'https://api-1-122j.onrender.com/content/read'; // Replace with actual API URL
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }
                    const data = await response.json();
                    const content = data.find(languagecontent => languagecontent.title.toLowerCase() === selectedTopic.toLowerCase());
                    setFilteredContent(content); // Update the state with the selected topic content
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchContent();
        }
    }, [selectedTopic]);

    const handleTopicClick = (topic) => {
        setSelectedTopic(topic); // Set the clicked topic in state
    };

    return (
        <div className='container interview-div flex mt-10 gap-3 rounded'>
            {/* Sidebar for large screens */}
            <div className='hidden lg:block w-[20%] bg-slate-900 rounded h-full'>
                <TutorialHeadlineFormat logo={img} description={`${name} Tutorial`} />

                {/* Accordion for tutorial sections */}
                <div className='mt-2'>
                    {interviews.length > 0 ? (
                        interviews.map((interview, interviewIndex) => (
                            interview.childtitle && (
                                interview.childtitle.sections && interview.childtitle.sections.length > 0 && (
                                    interview.childtitle.sections.map((section, sectionIndex) => (
                                        <Accordion key={`${interviewIndex}-${sectionIndex}`}>
                                            <AccordionSummary
                                                style={{ backgroundColor: '#1E293B', color: '#fff' }}
                                                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                                                aria-controls={`panel${interviewIndex}-content`}
                                                id={`panel${interviewIndex}-header`}
                                            >
                                                <Typography className='flex items-center justify-center'>
                                                    {section.title}  {/* Section title printed here */}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: '#1E293A', color: '#fff' }}>
                                                <Typography>
                                                    <div className='flex flex-col text-slate-200 bg-slate-800 w-full'>
                                                        {section.topics && section.topics.length > 0 ? (
                                                            section.topics.map((topic, topicIndex) => (
                                                                <p
                                                                    key={topicIndex}
                                                                    className='border-b cursor-pointer hover:text-green-500 font-nunito mb-3 border-dotted py-1 hover:bg-slate-800 duration-300'
                                                                    onClick={() => handleTopicClick(topic)} // Set the clicked topic
                                                                >
                                                                    {topic}  {/* Topic printed here */}
                                                                </p>
                                                            ))
                                                        ) : (
                                                            <p>No topics available</p>
                                                        )}
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                )
                            )
                        ))
                    ) : (
                        <p>No interviews available</p> // Display this if interviews array is empty
                    )}
                </div>
            </div>

            {/* Main content */}
            <div className='w-[100%] lg:w-[60%] h-full p-2 bg-slate-50 border-1 rounded'>
                {/* For small screens */}
                <div className='lg:hidden'>
                    <TutorialHeadlineFormat logo={img} description={`${name} Tutorial`} />
                </div>

                {/* Display tutorial content based on selected topic */}
                {filteredContent ? (
                    <div>
                        <div className='text-justify pt-4 font-nunito' dangerouslySetInnerHTML={{ __html: filteredContent.content }} />
                    </div>
                ) : (
                    interviews.map((data) => (
                        <div key={data._id}>
                            <div className='text-justify pt-4 font-nunito' dangerouslySetInnerHTML={{ __html: data.content }} />
                        </div>
                    ))
                )}
            </div>


            {/* Article Component */}
            <ArticleComponent />
        </div>
    );
};

export default TutorialDetails;
