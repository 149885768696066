import React, { useEffect, useRef } from 'react';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBrain, faBriefcase, faCode, faMagnifyingGlass, faPeopleArrows, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { a } from 'react-router-dom';

const Home = () => {

    const inputRef = useRef(null);

    useEffect(() => {
        const placeholderText = 'Search Your Desired Topic...';
        let index = 0;
        const intervalId = setInterval(() => {
            index = (index + 1) % (placeholderText.length + 1);
            inputRef.current.placeholder = placeholderText.slice(0, index);
        }, 150);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='bg-[#0e1117] relative pb-20 h-[550px]'>
            <div className='flex flex-col items-center justify-center text-center'>
                <h1 className='pt-8 md:pt-16 text-center text-slate-300 text-3xl sm:text-4xl lg:text-5xl font-bold font-sans'>Explore Your Coding Journey With Us</h1>
                <p className='mt-4 mx-3   font-nunito text-base sm:text-lg lg:text-xl text-slate-300 text-center'>
                    Explore opportunities from across the globe to learn, showcase skills,
                    <br className='hidden md:block' /> gain CV points, & get hired by your dream company.
                </p>

                <div className='flex items-center justify-center text-center mt-4 lg:mt-6 w-[80%] sm:w-[50%]'>
                    <input
                        type="text"
                        id="searchInput"
                        ref={inputRef}
                        className='w-full h-10 px-2 rounded-l outline-none'
                    />
                    <button className='bg-blue-600 text-slate-300 text-sm h-10 px-4 w-28 rounded-r items-center text-center justify-center'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                </div>
                <div className='flex w-[80%] sm:w-[60%] items-center mt-4 md:mt-3'>
                    <div className='text-white font-bold text-xl'>
                        <span>&lt;/&gt;</span>
                    </div>
                    <div className='h-1 rounded-sm mx-2 bg-gradient-to-r from-slate-950 to-blue-600 w-full'></div>
                    <div className='text-slate-300 mx-2 font-bold text-xl'>
                        <FontAwesomeIcon icon={faUserGraduate} className='text-white' size='1xl' />
                    </div>
                    <div className='h-1 rounded-sm bg-gradient-to-l from-slate-950 to-blue-600 w-full'></div>
                    <div>
                        <FontAwesomeIcon icon={faBriefcase} className='text-white' size='1xl' />
                    </div>
                </div>

                <div className='grid grid-cols-2 sm:grid-cols-2  text-start mt-4 md:mt-3 gap-2'>
                    <a href='/allcourse' className='flex items-center justify-center px-2 font-bold border-2 rounded py-2 border-purple-400 hover:border-white duration-100 hover:rounded-full text-white'>
                        <FontAwesomeIcon icon={faBookOpen} className='mr-2 bg-white p-2 rounded-full text-blue-500' />
                        Learning
                    </a>
                    <a href='/practice' className='flex items-center justify-center px-2 font-bold border-2 rounded py-2 border-purple-400 hover:border-white duration-100 hover:rounded-full text-white'>
                        <FontAwesomeIcon icon={faBrain} className='mr-2 bg-white p-2 rounded-full text-blue-500' />
                        Practice
                    </a>
                    <a href='/interview' className='flex items-center justify-center px-2 font-bold border-2 rounded py-2 border-purple-400 hover:border-white duration-100 hover:rounded-full text-white'>
                        <FontAwesomeIcon icon={faPeopleArrows} className='mr-2 bg-white p-2 rounded-full text-blue-500' />
                        Interview
                    </a>
                    <a href='/onlineide' className='flex items-center justify-center px-2 font-bold border-2 rounded py-2 border-purple-400 hover:border-white duration-100 hover:rounded-full text-white'>
                        <FontAwesomeIcon icon={faCode} className='mr-2 bg-white p-2 rounded-full text-blue-500' />
                        Online IDE
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Home;
