import React, { useRef, useState } from 'react';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import thumsup from '../../../Blog/img/thumsup.png';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function AddContent() {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [languageData, setLanguageData] = useState({
        title: '',
        content: ''
    });
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api-1-122j.onrender.com/content/create', languageData);
            console.log('Language created:', response.data);
            setLanguageData({
                title: '',
                content: ''
            });
            setSuccess(true);
        } catch (error) {
            console.error('Error creating language:', error);
        }
    };

    const handleCloseDialog = () => {
        setSuccess(false);
    };

    return (
        <div className='container'>
            <h2 className='text-4xl font-bold text-center mt-6'>Add Language</h2>
            {success && (
                <BootstrapDialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={success}>
                    <DialogTitle sx={{ m: 0, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="customized-dialog-title">
                        <img className="w-[200px]" src={thumsup} alt="" />
                        Language Added Successfully...
                    </DialogTitle>
                </BootstrapDialog>
            )}
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 gap-3 mt-6 mb-6'>
                    <div>
                        <label>Language Title:</label>
                        <input className="text-black rounded p-2 mt-2 w-full bg-white shadow border" type="text" name="title" value={languageData.title} onChange={(e) => setLanguageData({ ...languageData, title: e.target.value })} required />
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-3 mt-6 mb-6'>
                    <div>
                        <label>Content:</label>
                        <JoditEditor
                            ref={editor}
                            value={languageData.content}
                            tabIndex={1}
                            onBlur={newContent => setLanguageData({ ...languageData, content: newContent })}
                        />
                    </div>
                </div>
                <button type="submit" className="text-white rounded p-2 mt-3 font-bold border w-full bg-blue-500 mb-20">Submit</button>
            </form>
        </div>
    );
}

export default AddContent;
