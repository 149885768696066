import { faNewspaper, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import app from '../Firebase/Firebase.init';
import { Link } from 'react-router-dom';
const auth = getAuth(app);
const Dashbord = () => {
    const [handleLogOut] = useSignOut(auth);
    const [count, setCount] = useState(null);

    useEffect(() => {
        const fetchBlogPostCount = async () => {
            try {
                const response = await fetch('https://api-1-122j.onrender.com/blog/count');
                if (response.ok) {
                    const data = await response.json();
                    setCount(data.count);
                } else {
                    console.error('Failed to fetch blog post count');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchBlogPostCount();
    }, []);


    return (
        <div className="flex  bg-gray-100 min-h-screen">
            <aside className="hidden sm:flex sm:flex-col">

                <div className="flex-grow flex flex-col justify-between text-gray-500 bg-gray-950">
                    <nav className="flex flex-col mx-4 my-6 space-y-4">
                        <button onClick={() => handleLogOut(auth)}>
                            Logout
                        </button>
                        <a href="#" className="inline-flex items-center justify-center py-3 hover:text-gray-400 hover:bg-gray-700 focus:text-gray-400 focus:bg-gray-700 rounded-lg">
                            <span className="sr-only">Folders</span>
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                            </svg>
                        </a>
                        <a href="#" className="inline-flex items-center justify-center py-3 text-purple-600 bg-white rounded-lg">
                            <span className="sr-only">Dashboard</span>
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                        </a>
                        <a href="#" className="inline-flex items-center justify-center py-3 hover:text-gray-400 hover:bg-gray-700 focus:text-gray-400 focus:bg-gray-700 rounded-lg">
                            <span className="sr-only">Messages</span>
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                        </a>
                        <a href="#" className="inline-flex items-center justify-center py-3 hover:text-gray-400 hover:bg-gray-700 focus:text-gray-400 focus:bg-gray-700 rounded-lg">
                            <span className="sr-only">Documents</span>
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                        </a>
                    </nav>

                </div>
            </aside>
            <div className="flex-grow text-gray-800">

                <main className="p-6 sm:p-10 space-y-6">
                    <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
                        <div className="mr-6">
                            <h1 className="text-4xl font-semibold mb-2">Dashboard</h1>
                            <h2 className="text-gray-600 ml-0.5">Access for admin only</h2>
                        </div>
                        <div className="flex flex-wrap items-start justify-end -mb-3">
                            <button className="inline-flex px-5 py-3 text-purple-600 hover:text-purple-700 focus:text-purple-700 hover:bg-purple-100 focus:bg-purple-100 border border-purple-600 rounded-md mb-3">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 h-5 w-5 -ml-1 mt-0.5 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                </svg>
                                Manage dashboard
                            </button>
                            <button className="inline-flex px-5 py-3 text-white bg-purple-600 hover:bg-purple-700 focus:bg-purple-700 rounded-md ml-6 mb-3">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 h-6 w-6 text-white -ml-1 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Create new dashboard
                            </button>
                        </div>
                    </div>
                    <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6">

                        <Link to='/manageblog' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-purple-600 bg-purple-100 rounded-full mr-6">
                                <FontAwesomeIcon icon={faNewspaper} size='xl' />
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">Blogs Section</span>

                            </div>
                        </Link>


                        <Link to='/managetwelveblog' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">12 Class Section</span>

                            </div>
                        </Link>
                        <Link to='/addimg' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-red-600 bg-red-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                                </svg>
                            </div>
                            <div>
                                <span className="inline-block text-2xl font-bold">Img Add</span>
                            </div>
                        </Link>
                        <Link to='/manageinterview' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">Interviews</span>

                            </div>
                        </Link>
                        <Link to='/managelanguage' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">Language</span>

                            </div>
                        </Link>
                        <Link to='/managecontent' className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">Content</span>

                            </div>
                        </Link>

                        <div className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">{count}</span>
                                <span className="block text-gray-500">Total Blogs</span>
                            </div>
                        </div>
                        <div className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">{count}</span>
                                <span className="block text-gray-500">Total Blogs</span>
                            </div>
                        </div>
                    </section>
                    <section className="grid md:grid-cols-2 xl:grid-cols-4 xl:grid-rows-3 xl:grid-flow-col gap-6">
                        <div className="flex flex-col md:col-span-2 md:row-span-2 bg-white shadow rounded-lg">
                            <div className="px-6 py-5 font-semibold border-b border-gray-100">The number of applied and left students per month</div>
                            <div className="p-4 flex-grow">
                                <div className="flex items-center justify-center h-full px-4 py-16 text-gray-400 text-3xl font-semibold bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">Chart</div>
                            </div>
                        </div>
                        <div className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-yellow-600 bg-yellow-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                    <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">25</span>
                                <span className="block text-gray-500">Lections left</span>
                            </div>
                        </div>
                        <div className="flex items-center p-8 bg-white shadow rounded-lg">
                            <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-teal-600 bg-teal-100 rounded-full mr-6">
                                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div>
                                <span className="block text-2xl font-bold">139</span>
                                <span className="block text-gray-500">Hours spent on lections</span>
                            </div>
                        </div>
                        <div className="row-span-3 bg-white shadow rounded-lg">
                            <div className="flex items-center justify-between px-6 py-5 font-semibold border-b border-gray-100">
                                <span>Students by average mark</span>
                                <button type="button" className="inline-flex justify-center rounded-md px-1 -mr-1 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-600" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                    Descending
                                    <svg className="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <div className="overflow-y-auto" style={{ maxHeight: '24rem' }}>


                                <ul className="p-6 space-y-6">
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/women/82.jpg" alt="Annette Watson profile picture" />
                                        </div>
                                        <span className="text-gray-600">Annette Watson</span>
                                        <span className="ml-auto font-semibold">9.3</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="Calvin Steward profile picture" />
                                        </div>
                                        <span className="text-gray-600">Calvin Steward</span>
                                        <span className="ml-auto font-semibold">8.9</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/men/80.jpg" alt="Ralph Richards profile picture" />
                                        </div>
                                        <span className="text-gray-600">Ralph Richards</span>
                                        <span className="ml-auto font-semibold">8.7</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/men/79.jpg" alt="Bernard Murphy profile picture" />
                                        </div>
                                        <span className="text-gray-600">Bernard Murphy</span>
                                        <span className="ml-auto font-semibold">8.2</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/women/78.jpg" alt="Arlene Robertson profile picture" />
                                        </div>
                                        <span className="text-gray-600">Arlene Robertson</span>
                                        <span className="ml-auto font-semibold">8.2</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/women/77.jpg" alt="Jane Lane profile picture" />
                                        </div>
                                        <span className="text-gray-600">Jane Lane</span>
                                        <span className="ml-auto font-semibold">8.1</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/men/76.jpg" alt="Pat Mckinney profile picture" />
                                        </div>
                                        <span className="text-gray-600">Pat Mckinney</span>
                                        <span className="ml-auto font-semibold">7.9</span>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                            <img src="https://randomuser.me/api/portraits/men/75.jpg" alt="Norman Walters profile picture" />
                                        </div>
                                        <span className="text-gray-600">Norman Walters</span>
                                        <span className="ml-auto font-semibold">7.7</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col row-span-3 bg-white shadow rounded-lg">
                            <div className="px-6 py-5 font-semibold border-b border-gray-100">Students by type of studying</div>
                            <div className="p-4 flex-grow">
                                <div className="flex items-center justify-center h-full px-4 py-24 text-gray-400 text-3xl font-semibold bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">Chart</div>
                            </div>
                        </div>
                    </section>

                </main>
            </div>
        </div>
    );
};

export default Dashbord;


